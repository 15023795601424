import React, {useEffect, useState} from 'react'
import { Card, CardContent, CardMedia } from "@mui/material";
import Typography from "@mui/material/Typography";
import open from '../../images/open.jpg'
import closed from '../../images/closed.jpg'
import Api from "../../core/Api";

const IsOpen = () => {
    const [doorStatus, setDoorStatus] = useState({
        status: false,
        locked: false
    });

    const fetchStatus = () => {
        Api.fetch({
            endpoint: 'door/status'
        }).then((r) => {
            setDoorStatus(r.response)
        }, () => {
        })
    }

    useEffect(() => {
        fetchStatus()
        const i = setInterval(fetchStatus, 30000)
        return () => {
            clearInterval(i)
        }
    }, [])

    return <Card className={''}>
        <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
                Ist jemand da?
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
                Das Schild zeigt an ob jemand in der Werkstatt ist.
            </Typography>
        </CardContent>
        <CardMedia
            sx={{height: '250px'}}
            image={doorStatus.status ? open : closed}
        />
    </Card>
}

export default IsOpen
