import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import history from '../../core/history';
import {Route, Routes} from 'react-router';
import Dashboard from './Dashboard';
import Search from './Search';
import {AppBar, Container, Drawer, IconButton, ListItemButton, Toolbar} from '@mui/material';
import {ControlCamera, Group, Subscriptions} from '@mui/icons-material';
import Admin from './Admin';
import {signOut} from '@aws-amplify/auth';
import {fetchAuthSession} from 'aws-amplify/auth';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import UserTable from './Users/Table';
import SearchIcon from '@mui/icons-material/Search';
import Requests from './Requests';
import ImageIcon from '@mui/icons-material/Image';
import ImagesTable from './Images/List';

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const ListItemLink = (props) => {
  const {exact, ...newProps} = props;
  const pathname = history.location.pathname;
  const selected = !exact ? pathname.indexOf(newProps.to) === 0 : pathname === newProps.to;

  return (
    <ListItem {...newProps} component={Link} button selected={selected}>
      {newProps.children}
    </ListItem>
  );
};

const LoggedIn = () => {
  const classes = useStyles();
  const [drawerOpen, toggleDrawer] = React.useState(false);

  const [scopes, setScopes] = useState([]);
  const [groups, setGroups] = useState([]);
  useEffect(() => {
    const fetchSession = async () => {
      const session = await fetchAuthSession();
      setScopes(session?.tokens?.idToken?.payload?.scope?.split(' ') || []);
      setGroups(session?.tokens?.idToken?.payload.groups?.split(',') || []);
    };

    fetchSession();
  }, []);

  const isAdmin = scopes.includes('garage/door.write');

  const hasGroup = groups.includes('admin') || groups.includes('user');
  if (!hasGroup) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            p: 2,
          }}
        >
          <Typography variant="h6" sx={{mb: 2}}>
            Your account has to be activated by an admin
          </Typography>
          <Button variant="contained" color="primary" onClick={() => signOut()}>
            Logout
          </Button>
        </Paper>
      </Box>
    );
  }

  return (
    <Box height={'100%'}>
      <Drawer
        anchor={'left'}
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <div
          className={classes.list}
          role="presentation"
          onClick={() => toggleDrawer(false)}
          onKeyDown={() => toggleDrawer(false)}
        >
          <List>
            <ListItemLink to={'/'} exact>
              <ListItemIcon><DashboardIcon/></ListItemIcon>
              <ListItemText primary={'Dashboard'}/>
            </ListItemLink>
            <ListItemLink to={'/search'} exact>
              <ListItemIcon><SearchIcon/></ListItemIcon>
              <ListItemText primary={'Suche'}/>
            </ListItemLink>
            <ListItemLink to={'/requests'} exact>
              <ListItemIcon><Subscriptions/></ListItemIcon>
              <ListItemText primary={'Anfragen'}/>
            </ListItemLink>
            {isAdmin && <ListItemLink to={'/admin'} exact>
              <ListItemIcon><ControlCamera/></ListItemIcon>
              <ListItemText primary={'Admin'}/>
            </ListItemLink>}
            {scopes.includes('garage/user.read') && <ListItemLink to={'/users'} exact>
              <ListItemIcon><Group/></ListItemIcon>
              <ListItemText primary={'Benutzer'}/>
            </ListItemLink>}
            <ListItemLink to={'/images'} exact>
              <ListItemIcon><ImageIcon/></ListItemIcon>
              <ListItemText primary={'Bilder'}/>
            </ListItemLink>
          </List>
          <Divider/>
          <List>
            <ListItemButton onClick={() => signOut()}>
              <ListItemIcon><LogoutIcon/></ListItemIcon>
              <ListItemText primary={'Logout'}/>
            </ListItemButton>
          </List>
        </div>
      </Drawer>
      <Box sx={{display: 'flex', height: '100%', width: '100%'}}>
        <AppBar
          position='fixed'
        >
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                        onClick={() => toggleDrawer(true)}>
              <MenuIcon/>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Die Werkstatt
            </Typography>
            <Button onClick={() => signOut()} color="inherit">Logout</Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="xl" sx={{flexGrow: 1, overflow: 'auto', pb: 2}}>
          <Toolbar/>
          <Routes>
            <Route path="/" element={<Dashboard scopes={scopes}/>}/>
            <Route path="/search" element={<Search scopes={scopes}/>}/>
            <Route path="/search/:query" element={<Search scopes={scopes}/>}/>
            <Route path="/requests" element={<Requests scopes={scopes}/>}/>
            <Route path="/images" element={<ImagesTable scopes={scopes}/>}/>
            {isAdmin && <Route exact path="/admin" element={<Admin/>}/>}
            {scopes.includes('garage/user.read') && <Route exact path="/users" element={<UserTable/>}/>}
          </Routes>
        </Container>
      </Box>
    </Box>
  );
};

export default LoggedIn;
