import React from 'react';
import CurrentSong from './CurrentSong';
import IsOpen from './IsOpen';
import {Grid} from '@mui/material';

const Dashboard = ({scopes}) => {
  return (
    <Grid container spacing={2} sx={{mt: 0}}>
      <Grid item lg={4} md={6} xs={12}>
        <IsOpen/>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <CurrentSong scopes={scopes}/>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
