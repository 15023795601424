import React, {useCallback, useEffect, useState} from 'react';
import {
    Divider,
    Grid,
    IconButton,
    InputBase,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Paper
} from '@mui/material';
import {useDispatch} from 'react-redux';
import {push} from 'react-router-redux';
import SearchIcon from '@mui/icons-material/Search';
import Api from '../../core/Api';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import SetPlaylistButton from '../Utils/SetPlaylistButton';
import {useParams} from 'react-router';

const Search = ({scopes}) => {
    const params = useParams();
    const query = params.query;

    const dispatch = useDispatch();

    const [fetching, setFetching] = useState(false);

    const [searchString, setSearchString] = useState('');
    const [searchResult, setSearchResult] = useState([]);

    const onSearch = (e) => {
        e.preventDefault();

        dispatch(push('/search/' + searchString));
    };

    const queueSong = (id) => {
        setFetching(true);
        Api.fetch({
            method: 'POST',
            endpoint: 'spotify/queue',
            body: {
                value: id,
            },
        }).then((r) => {
        }, () => {
        }).then(() => {
            setFetching(false);
        });
    };

    const requestSong = (id) => {
        setFetching(true);
        Api.fetch({
            method: 'POST',
            endpoint: 'spotify/requests',
            body: {
                value: id,
            },
        }).then((r) => {
        }, () => {
        }).then(() => {
            setFetching(false);
        });
    }

    const executeSearch = useCallback(() => {
        if (!query) {
            return;
        }
        
        Api.fetch({
            method: 'POST',
            endpoint: 'spotify/search',
            body: {
                query: query,
                withPlaylists: scopes.includes('garage/spotify.write')
            },
        }).then((r) => {
            console.log('response');
            setSearchResult(r.response);
        }, () => {
        });
    }, [query]);

    useEffect(() => {
        setSearchString(query);
        executeSearch();
    }, [query, executeSearch]);

    const songs = (searchResult.songs || []).map((s) => {
        return <React.Fragment key={s.id}>
            <Divider/>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar variant="square" src={s.image}/>
                </ListItemAvatar>
                <ListItemText
                    primary={s.name}
                    secondary={(s.artists || []).join(', ')}
                />
                <ListItemSecondaryAction>
                    <IconButton disabled={fetching} edge="end" onClick={() => {
                        if (scopes.includes('garage/spotify.write')) {
                            queueSong(s.id);
                        } else {
                            requestSong(s.id);
                        }
                    }}>
                        <QueueMusicIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </React.Fragment>;
    });

    const playlists = (searchResult.playlists || []).map((s) => {
        return <React.Fragment>
            <Divider/>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar variant="square" src={s.image}/>
                </ListItemAvatar>
                <ListItemText
                    primary={s.name}
                />
                <ListItemSecondaryAction>
                    <SetPlaylistButton disabled={fetching} onPlay={(resolve, reject) => {
                        setFetching(true);
                        Api.fetch({
                            method: 'POST',
                            endpoint: 'spotify/playlist',
                            body: {
                                value: s.id,
                            },
                        }).then((r) => {
                            resolve();
                        }, () => {
                            reject();
                        }).then(() => {
                            setFetching(false);
                        });
                    }}/>
                </ListItemSecondaryAction>
            </ListItem>
        </React.Fragment>;
    });

    return (
        <React.Fragment>
            <form onSubmit={onSearch}>
                <Paper component="form"
                       sx={{
                           mt: 2,
                           padding: '2px 4px',
                           display: 'flex',
                           alignItems: 'center',
                       }}>
                    <InputBase
                        onChange={(e) => setSearchString(e.target.value)}
                        value={searchString}
                        sx={{
                            ml: 1,
                            flex: 1,
                        }}
                        placeholder="Suche..."
                    />
                    <IconButton onClick={onSearch} type="submit" sx={{
                        p: '10px',
                    }} aria-label="search">
                        <SearchIcon/>
                    </IconButton>
                </Paper>
            </form>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    {!!searchResult.songs && <Paper sx={{mt: 2}}><List
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Songs
                            </ListSubheader>
                        }
                    >
                        {songs}
                    </List></Paper>}                </Grid>
                <Grid item md={6} xs={12}>
                    {(!!searchResult.playlists && scopes.includes('garage/spotify.write')) &&
                        <Paper sx={{mt: 2}}><List
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Playlists
                                </ListSubheader>
                            }
                        >
                            {playlists}
                        </List></Paper>}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Search;
