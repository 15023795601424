import React, {Fragment, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from './LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {IconButton} from '@mui/material';

const SetPlaylistButton = (props) => {

  const {disabled, onDelete, t, description, buttonText, ...otherProps} = props;
  delete otherProps.tReady;

  const [fetching, setFetching] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = () => {

    setFetching(true);
    const promise = new Promise(((resolve, reject) => {
      props.onPlay(resolve, reject);
    }));

    promise.then(() => {
      handleClose();
    }).finally(() => setFetching(false));
  };

  return <Fragment>
    <IconButton
      disabled={disabled}
      onClick={handleClickOpen}
      edge="end">
      <PlayArrowIcon/>
    </IconButton>
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Bist du dir Sicher?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Sicher das du die aktuelle Playlist ersetzen möchtest?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={fetching} onClick={handleClose} autoFocus variant="contained">
          Abbrechen
        </Button>
        <LoadingButton loading={fetching} onClick={onClick} color="primary"
                       variant="contained">
          Abspielen
        </LoadingButton>
      </DialogActions>
    </Dialog>
  </Fragment>;
};

export default SetPlaylistButton;
