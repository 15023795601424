import {applyMiddleware, compose, createStore} from 'redux';
import {createRouterMiddleware} from "@lagunovsky/redux-react-router";
import history from './history';
import createRootReducer from './reducers'
import {thunk} from 'redux-thunk'
import {api} from "../middleware/api";

const store = createStore(
    createRootReducer(history),
    compose(
        applyMiddleware(
          createRouterMiddleware(history),
          thunk,
            api
        ),
    )
);

export default store;
