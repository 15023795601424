import Api from "../../core/Api";
import React, {useEffect, useState} from "react";
import {
    Button,
    Divider,
    Grid,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import {ThumbUp} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import * as PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";

function DialogAction(props) {
    return null;
}

DialogAction.propTypes = {children: PropTypes.node};
const Requests = ({scopes}) => {
    const [fetching, setFetching] = useState(false);
    const [saving, setSaving] = useState(false);
    const [requests, setRequests] = useState([]);
    const [selectedSong, setSelectedSong] = useState(null);

    const fetchRequests = () => {
        setFetching(true);
        Api.fetch({
            endpoint: 'spotify/requests'
        }).then(r => {
            const sortedResponse = r.response.sort((a, b) => b.userIds.length - a.userIds.length);
            setRequests(sortedResponse);
        }, () => {
        }).then(() => {
            setFetching(false);
        });
    }

    const queueSong = (id) => {
        setSaving(true);
        Api.fetch({
            method: 'POST',
            endpoint: 'spotify/queue',
            body: {
                value: id,
            },
        }).then(() => {
            fetchRequests()
            deleteSong(id);
        }, () => {
        }).then(() => {
            setSaving(false);
        });
    }

    const deleteSong = (id) => {
        setSaving(true);
        Api.fetch({
            method: 'DELETE',
            endpoint: 'spotify/requests/' + id,
        }).then(() => {
            fetchRequests()
        }, () => {
        }).then(() => {
            setSaving(false);
        });
    }

    const requestSong = (id) => {
        setSaving(true);
        Api.fetch({
            method: 'POST',
            endpoint: 'spotify/requests',
            body: {
                value: id,
            },
        }).then(() => {
            fetchRequests()
        }, () => {
        }).then(() => {
            setSaving(false);
        });
    }

    useEffect(() => {
        fetchRequests();
    }, [])


    const songs = (requests || []).map((s) => {
        return <TableRow key={s.id}>
            <TableCell>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar variant="square" src={s.image}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={s.name}
                        secondary={(s.artists || []).join(', ')}
                    />
                </ListItem>
            </TableCell>
            <TableCell>
                {s.userIds?.length || 0}
            </TableCell>
            <TableCell>
                    <IconButton disabled={fetching || saving}  onClick={() => {
                        if (scopes.includes('garage/spotify.write')) {
                            queueSong(s.id);
                        } else {
                            requestSong(s.id);
                        }
                    }}>
                        <ThumbUp/>
                    </IconButton>
                    {scopes.includes('garage/spotify.write') &&
                        <IconButton sx={{ml: 1}} disabled={fetching || saving}
                                    onClick={() => setSelectedSong(s)}
                                    edge="end">
                            <DeleteIcon/>
                        </IconButton>}
            </TableCell>
        </TableRow>;
    });


    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    {Boolean(requests.length) &&<TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Song</TableCell>
                                    <TableCell align="right">Votes</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {songs}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                    {!Boolean(requests.length) && <Paper sx={{mt: 2, p: 2}}>
                        <Typography variant="body1" align="center">
                            Keine Wünsche gefunden
                        </Typography>
                    </Paper>}
                </Grid>
            </Grid>
            <Dialog
                open={Boolean(selectedSong)}
                onClose={() => setSelectedSong(null)}
            >
                <DialogTitle>{`Sicher das du den Song "${selectedSong?.name}" löschen willst?`}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setSelectedSong(null)}>Schließen</Button>
                    <Button variant='contained' color='error' onClick={() => {
                        deleteSong(selectedSong.id);
                        setSelectedSong(null);
                    }}>LÖSCHEN</Button>
                </DialogActions>
            </Dialog>
        </>);
}

export default Requests
