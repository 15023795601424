import {Button, Card, CardContent, Grid, Box, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Api from '../../core/Api';

const Admin = () => {
  const [doorStatus, setDoorStatus] = useState({
    status: false,
    locked: false,
  });

  const fetchStatus = () => {
    Api.fetch({
      endpoint: 'door/status',
    }).then((r) => {
      setDoorStatus(r.response);
    }, () => {
    });
  };

  const changeStatus = (d) => {
    Api.fetch({
      endpoint: 'door/client-status',
      method: 'POST',
      body: d,
    }).then((r) => {
    }, () => {
    }).then(() => {
      fetchStatus();
    });
  };

  const toggleStatus = () => {
    changeStatus({
      status: !doorStatus.status,
      locked: doorStatus.locked,
    });
  };

  const toggleLock = () => {
    changeStatus({
      status: doorStatus.status,
      locked: !doorStatus.locked,
    });
  };

  useEffect(() => {
    fetchStatus();
    const i = setInterval(fetchStatus, 30000);
    return () => {
      clearInterval(i);
    };
  }, []);

  return <Grid container spacing={2}>
    <Grid item lg={4} md={6} xs={12}>
      <Card className={''}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Door Status
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {JSON.stringify(doorStatus)}
          </Typography>
        </CardContent>
      </Card>
      <Box mt={2}>
        <Button fullWidth color="primary" variant="contained"
                onClick={toggleStatus}>{doorStatus.status ? 'Turn off' : 'Turn on'}</Button>
      </Box>
      <Box mt={2}>
        <Button fullWidth color="secondary" variant="contained"
                onClick={toggleLock}>{doorStatus.locked ? 'Unlock' : 'Lock'}</Button>
      </Box>
    </Grid>
  </Grid>;
};

export default Admin;
