import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        position: 'relative',
    },
    wrapperFullWidth: {
        width: '100%'
    },
    buttonProgress: {
        color: theme.palette.primary.contrastText,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const LoadingButton = (props) => {

    const {loading, disabled, children, fullWidth, ...otherProps} = props;

    const classes = useStyles();

    return <div className={classes.root}>
        <div className={clsx({[classes.wrapper]: true, [classes.wrapperFullWidth]: fullWidth})}>
            <Button
                fullWidth={fullWidth}
                {...otherProps}
                disabled={loading || disabled}
            >
                {children}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </div>
    </div>;
};

export default LoadingButton;