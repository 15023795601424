import React, {useEffect, useState} from 'react';
import Api from '../../core/Api';
import {Card, CardContent, CardMedia, Divider, IconButton, InputBase, Paper, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import pause from '../../images/pause.svg';
import Box from '@mui/material/Box';
import RepeatIcon from '@mui/icons-material/Repeat';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import SkipNext from '@mui/icons-material/SkipNext';
import SkipPrevious from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {Pause, Search} from '@mui/icons-material';
import {useDispatch} from 'react-redux';
import {push} from 'react-router-redux';

const CurrentSong = ({scopes}) => {
  const dispatch = useDispatch();
  const [spotifyStatus, setSpotifyStatus] = useState({
    song: {},
    player: {},
  });

  const [fetching, setFetching] = useState(false);
  const [searchString, setSearchString] = useState('');

  const [volumeValue, setVolumeValue] = useState(0);

  useEffect(() => {
    setVolumeValue(spotifyStatus.player.volume || 0);
  }, [spotifyStatus]);

  const onSearch = (e) => {
    e.preventDefault();

    dispatch(push('/search/' + searchString));
  };
  const fetchSong = () => {
    Api.fetch({
      endpoint: 'spotify/status',
    }).then((r) => {
      setSpotifyStatus(r.response);
    }, () => {
    });
  };

  const postControl = (endpoint) => {
    setFetching(true);
    Api.fetch({
      method: 'POST',
      endpoint: 'spotify/' + endpoint,
    }).then((r) => {
      setSpotifyStatus(r.response);
    }, () => {
    }).then(() => {
      setFetching(false);
    });
  };

  const setVolume = (volume) => {
    setFetching(true);
    Api.fetch({
      method: 'POST',
      endpoint: 'spotify/volume',
      body: {
        value: volume,
      },
    }).then((r) => {
      setSpotifyStatus(r.response);
    }, () => {
    }).then(() => {
      setFetching(false);
    });
  };

  useEffect(() => {
    fetchSong();
    const i = setInterval(fetchSong, 30000);
    return () => {
      clearInterval(i);
    };
  }, []);

  let currentMinutes = Math.floor((spotifyStatus.song.progressMs / 1000 / 60) << 0);
  let currentSeconds = Math.floor((spotifyStatus.song.progressMs / 1000 % 60));

  const maximumMinutes = Math.floor((spotifyStatus.song.durationMs / 1000 / 60) << 0);
  const maximumSeconds = Math.floor((spotifyStatus.song.durationMs / 1000 % 60));

  if (currentMinutes > maximumMinutes && currentSeconds > maximumSeconds) {
    currentMinutes = maximumMinutes;
    currentSeconds = maximumSeconds;
  }

  const timeProgress =
    <React.Fragment>{currentMinutes.pad(2)}:{currentSeconds.pad(2)} / {maximumMinutes.pad(2)}:{maximumSeconds.pad(2)}</React.Fragment>;

  const isSong = !!spotifyStatus.song.name;
  const displayControls = scopes.includes('garage/spotify.write');

  return <React.Fragment>
    <Card sx={{mb: 2}}>
      <Box sx={{display: 'flex'}}>
        <CardMedia
            sx={{width: 151}}
            component="img"
            image={isSong ? spotifyStatus.song.image : pause}
        />
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <CardContent sx={{flex: '1 0 auto'}}>
            <Typography component="h6" variant="h6">
              Aktuell Läuft
            </Typography>
            <Typography component="h5" variant="h5">
              {isSong ? spotifyStatus.song.name : <i>Kein song</i>}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {spotifyStatus.song.artists ? spotifyStatus.song.artists.join(', ') : ''}
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              {isSong ? timeProgress : '--:-- / --:--'}
            </Typography>
          </CardContent>
        </Box>
      </Box>
    </Card>
    {displayControls && <React.Fragment>
      <Paper sx={{mt: 2}}>
        <Box display="flex" justifyContent="center">
          <Box p={1}>
            <IconButton disabled={fetching} onClick={() => postControl('previous')}>
              <SkipPrevious/>
            </IconButton>
          </Box>
          <Box p={1}>
            <IconButton disabled={fetching} onClick={() => postControl('toggle')}>
              {spotifyStatus.playing ? <Pause/> : <PlayArrowIcon/>}
            </IconButton>
          </Box>
          <Box p={1}>
            <IconButton disabled={fetching} onClick={() => postControl('next')}>
              <SkipNext/>
            </IconButton>
          </Box>
        </Box>
      </Paper>
      <Paper sx={{mt: 2}}>
        <Box display="flex">
          <Box p={1}>
            <IconButton disabled={fetching} color={spotifyStatus.player.shuffle ? 'primary' : 'inherit'}
                        onClick={() => postControl('shuffle')}>
              <ShuffleIcon/>
            </IconButton>
          </Box>
          <Box p={1}>
            <IconButton disabled={fetching}
                        color={spotifyStatus.player.repeat === 'context' ? 'primary' : 'inherit'}
                        onClick={() => postControl('repeat')}>
              <RepeatIcon/>
            </IconButton>
          </Box>
          <Divider orientation="vertical" flexItem/>
          <Box flexGrow={1}/>
          <Stack spacing={2} direction="row" sx={{px: 2, width: '300px'}} alignItems="center">
            <VolumeDown/>
            <Slider
                onChangeCommitted={(e, value) => setVolume(value)}
                onChange={(e, value) => setVolumeValue(value)}
                value={volumeValue} disabled={fetching}/>
            <VolumeUp/>
          </Stack>
        </Box>
      </Paper>
    </React.Fragment>
    }
    <form onSubmit={onSearch}>
      <Paper component="form" sx={{
        mt: 2,
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
      }}>
        <InputBase
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            sx={{ml: 1, flex: 1}}
            placeholder="Suche..."
        />
        <IconButton onClick={onSearch} type="submit" sx={{padding: '10px'}} aria-label="search">
          <Search/>
        </IconButton>
      </Paper>
    </form>
  </React.Fragment>;
};

export default CurrentSong;
