import store from './store';
import HttpStatus from 'http-status-codes';
import {fetchAuthSession, signOut} from 'aws-amplify/auth';

const BASE_URL = '/api/';

export default class Api {

  // Now you can write your own methods easily
  static async fetch(options) {

    const {
      endpoint,
      method = 'GET',
      parameter = {},
      header = {},
      body,
      ignoreErrorCodes = [],
    } = options;

    const session = await fetchAuthSession();
    const token = session.tokens.idToken.toString();

    header.Authorization = `Bearer ${token}`;

    let config = {
      method: method,
    };

    header['Accept'] = 'application/json';

    if (body instanceof FormData) {
      config.body = body;
    } else if (typeof body !== 'undefined') {
      config.body = JSON.stringify(body);
      header['Content-Type'] = 'application/json';
    }

    config.headers = header;

    let params = '';
    if (Object.keys(parameter).length) {
      for (const key in parameter) {
        if (typeof parameter[key] !== 'object') {
          continue;
        }

        for (const objectKey in parameter[key]) {
          if (typeof parameter[key][objectKey] === 'undefined') {
            continue;
          }

          parameter[key + '[' + objectKey + ']'] = parameter[key][objectKey];
        }

        delete parameter[key];
      }

      params = '?' + (new URLSearchParams(parameter));
    }

    store.dispatch({
      type: 'ADD_REQUEST',
    });

    const prefix = process.env.REACT_APP_API || '';

    let baseUrl = BASE_URL;

    const response = await fetch(prefix + baseUrl + endpoint + params, config);

    const text = await response.json().catch(() => ({}));
    store.dispatch({
      type: 'REMOVE_REQUEST',
    });

    if (response.status === 401) {
      await signOut();
    } else if (!response.ok) {

      let errorText = text.message;

      if (!errorText && response.status >= 500) {
        errorText = HttpStatus.getStatusText(response.status);
      }

      if (!errorText) {
        errorText = 'Ein Fehler ist aufgetreten';
      }

      if (ignoreErrorCodes.indexOf(response.status) === -1) {
        store.dispatch({
          type: 'ADD_ALERT',
          message: errorText,
          style: 'error',
        });
      }

      return Promise.reject({
        message: errorText,
        raw: text,
        code: response.status,
        header: response.headers,
      });
    }

    return {
      response: text,
      header: response.headers,
    };
  }
};