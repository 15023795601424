import React from 'react';
import Api from '../../../core/Api';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const View = ({image, onClose}) => {

  const [loading, setLoading] = React.useState(true);
  const [viewImage, setViewImage] = React.useState(null);

  React.useEffect(() => {
    setLoading(true);
    Api.fetch({
      endpoint: 'images/images/' + image.id,
      method: 'GET',
    }).then((r) => {
      setViewImage(r.response);
    }, () => {
    }).then(() => {
      setLoading(false);
    });
  }, []);

  if(!viewImage) return (<Box sx={{
    width: 128,
    height: 128,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}><CircularProgress size={64} /></Box>);

  return (<Box
      onClick={() => onClose()}
      sx={{width: '100%', height: '100%', position: 'relative'}}><img
      onClick={(e) => e.stopPropagation()}
      key={viewImage.url}
    style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
  }}
    alt={viewImage.url}
    src={`${viewImage.url}`}
  /></Box>);
};
export default View;