import {Component} from 'react';
import {connect} from 'react-redux';
import {enqueueSnackbar} from 'notistack';

class Snackbars extends Component {

  displayed = [];

  storeDisplayed = (id) => {
    this.displayed = [...this.displayed, id];
  };

  removeDisplayed = (id) => {
    this.displayed = this.displayed.filter(key => id !== key);
  };

  componentDidUpdate = () => {
    const {notifications = []} = this.props;

    notifications.forEach(({id, message, style}) => {

      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(id)) return;

      // Display snackbar using notistack
      enqueueSnackbar(message, {
        key: id,
        variant: style,
        onExited: () => {
          this.props.removeSnackbar(id);
          this.removeDisplayed(id);
        },
      });
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(id);
    });
  };

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeSnackbar(id) {
      dispatch({
        type: 'REMOVE_ALERT',
        id: id,
      });
    },
  };
};

function mapStateToProps(state) {
  const notifications = state.alerts || [];

  return {notifications};
}

export default connect(mapStateToProps, mapDispatchToProps)(Snackbars);