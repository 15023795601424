import React, {useEffect, useState} from "react";
import Api from "../../../core/Api";
import {DataGrid, GridActionsCellItem, GridToolbarContainer, GridToolbarQuickFilter} from '@mui/x-data-grid';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select
} from "@mui/material";
import {Groups, MoreVert, Refresh} from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

const groups = {
    user: 'Benutzer',
    admin: 'Admin'
}

const columns = ({openMenu}) => ([
    {
        field: 'actions', type: 'actions', width: 30, label: '', getActions: (params) => {
            return [<GridActionsCellItem
                icon={<MoreVert/>}
                onClick={(e) => openMenu(e.currentTarget, params.row)}
                label={'More'}/>];
        }
    },
    {
        field: 'name', headerName: 'Name', minWidth: 200, flex: 1
    }, {
        field: 'email', headerName: 'Email', minWidth: 300, flex: 1
    }, {
        field: 'groups', editable: false, headerName: "Gruppen", minWidth: 300, renderCell: ({value}) => {
            return value?.filter((g) => ['user', 'admin'].includes(g)).map((group) => <Chip key={group}
                                                                                            label={groups[group] || group}/>) || null;
        }, flex: 1,
    }
]);

const UserTable = () => {
    const [fetching, setFetching] = useState(false);
    const [saving, setSaving] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [editGroupState, setEditGroupState] = useState(null);

    const [openUserMenu, setOpenUserMenu] = useState(null);

    const fetchUsers = () => {
        setFetching(true);
        Api.fetch({
            endpoint: 'users'
        }).then(r => {
            setUsers(r.response);
        }, () => {
        }).then(() => {
            setFetching(false);
        });

    }

    useEffect(() => {
        fetchUsers();
    }, [])

    const saveGroups = () => {
        setSaving(true);
        Api.fetch({
            endpoint: 'users/' + editGroupState.username + '/groups',
            method: 'POST',
            body: {
                groups: selectedGroups
            }
        }).then(() => {
            setEditGroupState(null);
            fetchUsers();
        }, () => {
        }).then(() => {
            setSaving(false);
        });
    }

    return <><Paper
        sx={{height: '100%'}}
    ><DataGrid
        loading={fetching}
        slots={{
            toolbar: () => (
                <GridToolbarContainer sx={{p: 1}}>
                    <GridToolbarQuickFilter/>
                    <IconButton onClick={fetchUsers}>
                        {fetching ? <CircularProgress size={24}/> : <Refresh/>}
                    </IconButton>
                </GridToolbarContainer>
            ),
        }}
        rows={users}
        columns={columns({
            openMenu: (anchor, row) => {
                setOpenUserMenu({
                    anchor, row
                });
            }
        })}
        initialState={{
            sorting: {
                sortModel: [{field: 'email', sort: 'asc'}],
            },
        }}
        pageSizeOptions={[2]}
    />

    </Paper>
        <Menu open={Boolean(openUserMenu)} anchorEl={openUserMenu?.anchor} onClose={() => setOpenUserMenu(null)}>
            <MenuItem onClick={() => {
                setEditGroupState(openUserMenu.row);
                setSelectedGroups(openUserMenu.row.groups);
                setOpenUserMenu(null);
            }}>
                <ListItemIcon>
                    <Groups/>
                </ListItemIcon>
                <ListItemText>Gruppen bearbeiten</ListItemText>
            </MenuItem>
        </Menu>
        <Dialog open={Boolean(editGroupState)} onClose={saving ? null : () => setEditGroupState(null)} fullWidth={true}
                minWidth='md'>
            <DialogTitle>Edit Groups</DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{m: 1}}>
                    <InputLabel>Gruppen</InputLabel>
                    <Select
                        s multiple
                        value={selectedGroups || []}
                        input={<OutlinedInput label="Gruppen"/>}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 48 * 4.5 + 8,
                                    width: 250,
                                }
                            }
                        }}
                        onChange={(e) => setSelectedGroups(e.target.value)}
                    >
                        {Object.keys(groups).map((group) => (
                            <MenuItem key={group} value={group}>
                                {groups[group]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={saving}
                    onClick={() => setEditGroupState(null)}>Cancel</Button>
                <Button
                    disabled={saving}
                    onClick={() => saveGroups()}>Save</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default UserTable;
