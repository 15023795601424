import React, {useCallback} from 'react';
import Box from '@mui/material/Box';
import {useDropzone} from 'react-dropzone';
import {Button, Fab, IconButton, ImageList, ImageListItem, ImageListItemBar, ListItemAvatar, Menu, MenuItem} from '@mui/material';
import {Cancel, Check, Delete, FileUpload, MoreVert} from '@mui/icons-material';
import Api from '../../../core/Api';
import {fetchAuthSession} from 'aws-amplify/auth';
import {useMediaQuery, useTheme} from '@mui/system';
import InfiniteScroll from 'react-infinite-scroller';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import ListItemIcon from '@mui/material/ListItemIcon';
import View from './View';

const ImagesTable = ({scopes}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  let cols;
  if (isXs) {
    cols = 1;
  } else if (isSm) {
    cols = 2;
  } else if (isMd) {
    cols = 3;
  } else if (isLg) {
    cols = 4;
  }

  const [images, setImages] = React.useState({});
  const [searchAfter, setSearchAfter] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [acceptedFiles, setAcceptedFiles] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [viewImage, setViewImage] = React.useState(null);
  const {getRootProps, getInputProps, open} = useDropzone({
    noClick: true,
    accept: {'image/*': []},
    onDropAccepted: (files) => {
      setAcceptedFiles(files.map(file => {
        return {
          file: file,
          status: 'open',
        };
      }));
    },
  });
  const [imageMenuOpen, setImageMenuOpen] = React.useState(null);
  const [imageToDelete, setImageToDelete] = React.useState(null);

  const upload = async () => {
    setUploading(true);
    const mainPromise = new Promise(async (resolve, reject) => {
      const authSession = await fetchAuthSession();
      const promises = acceptedFiles.map((file, i) => {
        return new Promise(async (resolve, reject) => {
          setAcceptedFiles((a) => a.map((f, index) => {
            if (index === i) {
              return {
                ...f,
                status: 'uploading',
              };
            }
            return f;
          }));
          try {
            const response = await Api.fetch({
              endpoint: 'images/upload',
              method: 'POST',
              body: {
                fileName: file.file.path,
                size: file.file.size,
                contentType: file.file.type,
              },
            });

            await fetch(response.response.url, {method: 'PUT', body: file.file, headers: {'x-amz-meta-userid': authSession.userSub}});
            setAcceptedFiles((a) => a.map((f, index) => {
              if (index === i) {
                return {
                  ...f,
                  status: 'done',
                };
              }
              return f;
            }));
            resolve();
          } catch (e) {
            setAcceptedFiles((a) => a.map((f, index) => {
              if (index === i) {
                return {
                  ...f,
                  status: 'error',
                };
              }
              return f;
            }));
            reject(e);
          }
        });
      });
      Promise.all(promises).then(() => {
      }, () => {
      }).then(() => {
        resolve();
        setUploading(false);
      });
    });

    mainPromise.then(() => {
      console.log('done');
    });
  };

  const deleteImage = async () => {
    setDeleting(true);
    try {
      await Api.fetch({
        endpoint: 'images/images/' + imageToDelete.id,
        method: 'DELETE',
      });
      setImages((images) => {
        const newImages = {...images};
        delete newImages[imageToDelete.url];
        return newImages;
      });
    } catch (e) {
      console.error(e);
    }
    setDeleting(false);
    setImageToDelete(null);
  };

  const searchImages = useCallback(async (searchAfter) => {
    setLoading(true);
    try {
      const response = await Api.fetch({
        endpoint: 'images/images',
        method: 'GET',
        parameter: {
          searchAfter,
        },
      });
      if (searchAfter === null) {
        const imageData = {};
        response.response.images?.forEach((image) => {
          imageData[image.url] = image;
        });
        setImages(imageData);
      } else {
        setImages((images) => {
          const newImages = {};
          response.response.images?.forEach((image) => {
            newImages[image.url] = image;
          });
          return {...images, ...newImages};
        });
      }
      if (!response.response.images || response.response.images?.length === 0) {
        setHasMore(false);
      }
      setSearchAfter(response.response.searchAfter);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);

  React.useEffect(() => {
    searchImages(null);
  }, [searchImages]);

  const loadMore = async () => {
    if (loading || !hasMore) {
      return;
    }
    await searchImages(searchAfter);
  };

  const allDone = acceptedFiles.length > 0 && acceptedFiles.every((file) => file.status === 'done');

  return <>
    <Box {...getRootProps({sx: {height: '100%', overflow: 'auto'}})} id="scroll-container">
      <InfiniteScroll
        pageStart={0}
        initialLoad={false}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={<div className="loader" key={0}>Loading ...</div>}
        useWindow={false}
      >
        <input {...getInputProps()} />
        <ImageList variant="standard" cols={cols} gap={8}>
          {Object.values(images).map((item) => (
            <ImageListItem
              key={item.url}
              onClick={() => {
                setViewImage(item);
              }}
              sx={(t) => ({
                cursor: 'pointer',
                backgroundColor: t.palette.background.default,
                '&:hover': {
                  cursor: 'pointer',
                },
              })}
            >
              <img
                alt={item.url}
                src={`${item.url}`}
                loading="lazy"
              />
              <ImageListItemBar
                title={moment(item.createdAt).format('DD.MM.YYYY HH:mm')}
                position="top"
                actionIcon={
                  (scopes.includes('garage/images.admin')) ?
                    <IconButton onClick={(e) => {
                      setImageMenuOpen({
                      anchor: e.currentTarget, image: item,
                    });
                      e.stopPropagation();
                    }}>
                      <MoreVert/>
                    </IconButton> : null
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </InfiniteScroll>
    </Box>
    <Fab color="primary" sx={{
      position: 'absolute',
      bottom: 16,
      right: 16,
    }} onClick={open}>
      <FileUpload/>
    </Fab>
    <Menu
      anchorEl={imageMenuOpen?.anchor}
      open={Boolean(imageMenuOpen)}
      onClose={() => setImageMenuOpen(null)}
    >
      <MenuItem onClick={() => {
        setImageToDelete(imageMenuOpen?.image);
        setImageMenuOpen(null);
      }}>
        <ListItemIcon>
          <Delete fontSize="small"/>
        </ListItemIcon>
        Löschen
      </MenuItem>
    </Menu>
    <Dialog
      fullWidth maxWidth="sm"
      open={acceptedFiles.length > 0} onClose={uploading ? null : () => setAcceptedFiles([])} scroll="paper">
      <DialogTitle>Hochladen</DialogTitle>
      <DialogContent dividers>
        <List>
          {acceptedFiles.map((file, index) => {

            let action = <IconButton
              onClick={() => {
                setAcceptedFiles(acceptedFiles.filter((_, i) => i !== index));
              }}
            >
              <Delete/>
            </IconButton>;
            if (file.status === 'uploading') {
              action = <CircularProgress/>;
            } else if (file.status === 'done') {
              action = <Check color="primary"/>;
            } else if (file.status === 'error') {
              action = <Cancel color="error"/>;
            }

            return <ListItem key={index}
                             secondaryAction={action}
            >
              <ListItemAvatar>
                <Avatar
                  variant="square"
                  src={URL.createObjectURL(file.file)}
                />
              </ListItemAvatar>
              <ListItemText primary={file.file.path}/>
            </ListItem>;
          })}
        </List>
      </DialogContent>
      <DialogActions>
        {allDone ? <Button onClick={() => setAcceptedFiles([])}>Schließen</Button> : <>
          <Button disabled={uploading} onClick={() => setAcceptedFiles([])}>Abbrechen</Button>
          <Button disabled={uploading} onClick={upload}>Hochladen</Button>
        </>}
      </DialogActions>
    </Dialog>
    <Dialog
      open={Boolean(imageToDelete)}
      onClose={deleting ? null : () => setImageToDelete(null)}
    >
      <DialogTitle>{`Sicher das du das Bild löschen möchtest?`}</DialogTitle>
      <DialogActions>
        <Button disabled={deleting} onClick={() => setImageToDelete(null)}>Schließen</Button>
        <Button disabled={deleting} variant="contained" color="error" onClick={() => {
          deleteImage(imageToDelete.id);
        }}>LÖSCHEN</Button>
      </DialogActions>
    </Dialog>
    <Dialog
      open={Boolean(viewImage)}
      maxWidth="lg"
      onClose={() => setViewImage(null)}
      PaperProps={{
        sx: {
          width: '100%',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
      PaperComponent={Box}
    >
      {viewImage ? <View image={viewImage} onClose={() => setViewImage(null)}/> : null}
    </Dialog>
  </>;
};

export default ImagesTable;