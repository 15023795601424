import React from 'react';
import App from './components/App/App';
import {createRoot} from 'react-dom/client';
import {Amplify} from 'aws-amplify';
import {I18n} from 'aws-amplify/utils';
import {translations} from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import './styles/amplify.css';
I18n.putVocabularies(translations);
I18n.setLanguage('de');

Amplify.configure(
  {
    Auth: {
      Cognito: {
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolClientId: process.env.REACT_APP_CLIENT_ID,
        authenticationFlowType: 'USER_SRP_AUTH',
        allowGuestAccess: false,
        loginWith: {
          email: true,
          oauth: {
            providers: ['Google'],
            redirectSignIn: [window.location.origin],
            redirectSignOut: [window.location.origin],
            responseType: 'code',
            scopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            domain: process.env.REACT_APP_COGNITO_URL
          },
        }
      },
    },
    Analytics: {
      disabled: true,
    },
  },
);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App/>);