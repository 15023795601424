import React from 'react';
import {ReduxRouter} from '@lagunovsky/redux-react-router';
import {Button, createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import {Authenticator, defaultDarkModeOverride, ThemeProvider as AmplifyThemeProvider} from '@aws-amplify/ui-react';
import {Provider} from 'react-redux';
import {SnackbarProvider} from 'notistack';
import {history, store} from '../../core';
import {Route, Routes} from 'react-router';
import Snackbars from '../Snackbar/Snackbars';
import background from '../../images/background.jpeg';
import LoggedIn from '../LoggedIn/LoggedIn';
import {deDE as coreDeDE} from '@mui/material/locale';
import {GlobalStyles} from '@mui/system';

const theme = createTheme(
  {
    palette: {
      mode: 'dark',
    },
  },
  coreDeDE,
);

// eslint-disable-next-line no-extend-native
Number.prototype.pad = function(size) {
  let s = String(this);
  while (s.length < (size || 2)) {
    s = '0' + s;
  }
  return s;
};

const App = (props) => {

  // add action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  // customized
  const action = key => (<Button onClick={onClickDismiss(key)}>
    Schließen
  </Button>);

  return <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AmplifyThemeProvider theme={{overrides: [defaultDarkModeOverride]}} colorMode='dark' style={{height: '100%'}} >
        <GlobalStyles styles={{
          html: {
            height: '100%',
          },
          body: {
            height: '100%',
            backgroundImage: 'url(' + background + ')',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
          },
          '#root': {
            height: '100%',
          },
        }}/>
        <CssBaseline/>
        <SnackbarProvider ref={notistackRef} maxSnack={3} action={action} autoHideDuration={6000}
                          preventDuplicate={true}>
          <Snackbars/>
          <Authenticator hideSignUp >
            {(signOut) => (
              <ReduxRouter history={history}>
                <Routes>
                  <Route path="*" element={<LoggedIn/>}/>
                </Routes>
              </ReduxRouter>)}
          </Authenticator>
        </SnackbarProvider>
      </AmplifyThemeProvider>
    </ThemeProvider>
  </Provider>;
};

export default App;
